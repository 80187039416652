.LinkItem {
    color: #999696;
    font-size: 1.1rem;
    margin: 0 10px;
    text-decoration: none;
}

.LinkItem.active {
    color: #323232;
    font-weight: 700;
}

@media (max-width: 500px) {
    .LinkItem {
        flex-flow: row;
        font-size: 2rem;
        margin: 10px 40px;
        text-align: left;
    }
}
