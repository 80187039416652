.Button {
    border: none;
    cursor: pointer;
    font-family: inherit;
    font-size: 1rem;
    font-weight: 600;
    outline: none;
}

.Primary {
    background-color: #0052cc;
    border-radius: 40px;
    color: white;
    margin-left: 25px;
    padding: 15px 20px;
}

.Outline {
    background: #ffffff;
    border: 1px solid #dedede;
    border-radius: 10px;
    color: #292929;
    font-weight: 600;
    height: max-content;
    padding: 10px 30px;
}