.Speakers {
    display: grid;
    gap: 40px;
    grid-template-columns: repeat(4, 1fr);
}

.Speakers-header {
    align-items: flex-end;
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
}

.Speakers-header > h2 {
    font-weight: 300;
    margin: 0;
    text-align: left;
}

.Speakers-header > h2 > b {
    font-size: 2.5rem;
    font-weight: 700;
    color: #292929;
}

@media (max-width: 1200px) {
    .Speakers {
        gap: 20px;
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 1000px) {
    .Speakers {
        gap: 20px;
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 700px) {
    .Speakers {
        gap: 20px;
        grid-template-columns: repeat(1, 1fr);
    }
}

@media (max-width: 500px) {
    .Speakers-header {
        align-items: normal;
        flex-flow: column;
    }

    .Speakers-header > h2 {
        margin: 0 0 15px 0;
    }
}
