.Logo {
    display: flex;
    text-decoration: none;
}

.Logo > img {
    width: 50px;
}

.Logo > p {
    color: #323232;
    font-weight: 700;
    font-size: 1.3rem;
    margin-left: 20px;
}
